<template>
    <div class="contain">
        <template>
        <div class="col-6-file" v-for="(v,k) in (dataList||[])" :key="k">
            <span class="label-x" @click="delImages(v.lktf_id)">X</span>
            <div class="box-file" v-if="typeFile(v.lktf_file) == 'pdf'" @click="previewPDF(uploader(v.lktf_file))">
                <img :src="baseUrl+'/img/file.png'" alt="" class="plus-ic"><br/>
                <small>{{(v.lktf_file||"").limitChar(50)}}</small>  
            </div>
            <div class="box-file" v-else-if="typeFile(v.lktf_file) == 'docx'||typeFile(v.lktf_file) == 'doc'||typeFile(v.lktf_file) == 'xls'||typeFile(v.lktf_file) == 'xlsx'" @click="previewDoc(uploader(v.lktf_file))">
                <img :src="baseUrl+'/img/file.png'" alt="" class="plus-ic"><br/>
                <small>{{v.lktf_file.limitChar(50)}}</small>
            </div>
            <div class="box-file" v-else @click="previewImg(uploader(v.lktf_file))">
                <img :src="baseUrl+'/img/file.png'" alt="" class="plus-ic"><br/>
                <small>{{v.lktf_file.limitChar(50)}}</small>
            </div>
        </div>        
        </template>


        <div class="col-6">
        <div class="box-plus">
            <img :src="baseUrl+'/img/plus.png'" alt="" class="plus-ic">
        </div>
        <div class="upload-tag">
            <Uploader isDocument mobileUpload :isClick="true" v-model="file" type="doc_umum"/>                     
        </div>
        </div>


        <div class="box-pdf-prev" v-if="pdfPreview">
        <div class="close-prev-docx" @click="closePDF()">X</div>      
            <vue-pdf-app :pdf="pdfPreview"></vue-pdf-app>
        </div>
        
        <div class="box-img-prev" v-if="docxPreview">    
        <span class="close-prev-docx" @click="closeDoc()">X</span>
        <iframe 
        :src="'https://view.officeapps.live.com/op/embed.aspx?src='+docxPreview" width='100%' height='100%' frameborder='0'>This is an embedded <a target='_blank' href='http://office.com'>Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps'>Office Online</a>.</iframe>
        </div>

        <div class="box-img-prev" v-if="imgPreview">
        <span class="close-prev" @click="closePrev()">X</span>
        <img :src="imgPreview" alt="" class="img-prev">
        </div>
        
    </div>
</template>
<script>
import Uploader from '@/components/Uploader'
import Gen from '@/libs/Gen.js'
import VuePdfApp from "vue-pdf-app"
// import this to use default icons for buttons

export default {
    components: {
        Uploader,VuePdfApp
    },
    data() {
        return {
            file: "",
            baseUrl: "",
            dataList: [],
            imgPreview: '',
            docxPreview: '',
            pdfPreview: '',
            base64 : ''
        }
    },
    computed:{
        apiUrl() {
            return process.env.VUE_APP_API_URL
        },
    },
    methods: {
        openHandler(pdfApp) {
          window._pdfApp = pdfApp
        },
        uploader(filename, def="/assets/images/user-placeholder.svg") {
            def = process.env.VUE_APP_MODE != 'local' ? '/backoffice'+def : def
            if(!filename){
                return def
            }
            return this.apiUrl + '/uploader/' + filename
        },
        delImages(id){
            let data = {
                id : id,
                token: this.$route.query.token
            }             
            Gen.apiRestMobile('/delete-file-mobile', {data: data}, 'POST').then(res=>{
                this.getList()
            })
        },
        onSubmit(){
            let data = {
                file : this.file,
                id : this.$route.query.id,
                token: this.$route.query.token
            }             
            Gen.apiRestMobile('/upload-file-mobile', {data: data}, 'POST').then(res=>{
                this.getList()
                setTimeout(()=>{
                    this.file = ""
                },500)
            })
        },
        getList(){
            const appLoading = document.getElementById('splashScreen')
            if (appLoading) {
                appLoading.classList.add('loaded')
                document.body.classList.remove('overflow-hidden')
            }
            
            let data = {
                id : this.$route.query.id,
                token: this.$route.query.token
            }             
            Gen.apiRestMobile('/get-upload-file-mobile',{
               params: data
            }).then(res=>{
                this.dataList = res.data.data
            })
        },
        typeFile(file=""){
            let split = file.split(".")
            let format = split[split.length-1]
            return format
        },
        previewPDF(files){
            Gen.apiRestMobile("/get-base64",{params:{url: files}}).then(res=>{
                this.base64 = res.data
                this.pdfPreview = this.base64
            })
        },
        closePDF(){
            this.pdfPreview = ''
            this.base64 = ''
        },
        previewDoc(files){
            this.docxPreview = files
        },
        closeDoc(){
            this.docxPreview = ''
        },
        previewImg(files){
            this.imgPreview = files
        },
        closePrev(){
            this.imgPreview = ''
        },
    },mounted() {
        console.log(this.$children)
        this.getList()
        this.typeFile()
    },watch: {
     'file'(v){
       if(v){
         this.onSubmit()
       }
     }
   },
}

</script>
<style lang="scss" scoped>
    @import "~vue-pdf-app/dist/icons/main.css";
    .pdf-app{
        width: 100% !important;
    }
    .file-upload__label_pict {
        display: block;
        color: #0083D7;
        border-radius: 4px;
        text-align: center;
        padding: 7.5px 16px;
        background-color: #e1f5fe;
        font-size: 14px;
        margin-bottom: 0px !important;
        background-color: #cccccc;
        cursor: pointer;
    }

    .fix-section{
        position: static !important;
        transform: none !important;
    }
    .close-prev{
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 30px;
    color: white;
    z-index: 9999999;
    }
    .annotationLayer section {
        position: static;
        transform: none!important;
    }
    .span-add-width{
    width : 100% !important;
    position: relative;
    display: block;
    }
    .close-prev-docx{
    position: fixed;
    right: 20px;
    top: 20px;
    font-size: 30px;
    color: #cccccc;
    z-index: 9999999;
    }
    .box-pdf-prev{  
    z-index: 9999;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .5);
    }
    .box-plus{
    margin-top: 15px;
    text-align: center;
    }
    .box-img-prev{
    z-index: 9999;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .5);
    }
    .img-prev{
    width: 90%;
    position: absolute;
    left: 4%;
    top: 25%;
    }
    .col-6{
    width: 45%;
    margin-left: 2.5%;
    margin-bottom: 10px;
    display: block;
    height: 145px;
    position: relative;
    border-radius: 4px;
    text-align: center;
    background-color: #e1f5fe;
    border: 1px solid #0083D7;
    }
    .col-6-file{
    width: 45%;
    margin-left: 2.5%;
    margin-bottom: 10px;
    display: block;
    height: 145px;
    position: relative;
    border-radius: 4px;
    text-align: center;
    background-color: #DDE1E6;
    border: 1px solid #0083D7;
    }
    .plus-ic{
    width : 35px
    }
    .label-x{
    position: absolute;
    color: darkgray;
    right: 10px;
    top: 10px;
    }
    .upload-container{
    position: relative;
    .image-preview{
        position: relative;
        .remove{
        position: absolute;
        display: block;
        background: #ff6767;
        width: 18px;
        height: 18px;
        color: #fff;
        padding: 0px 5px;
        line-height: 15px;
        border-radius: 50%;
        top: -5px;
        right: 5px;
        }
    }
    }
    .alert-res-error{
        color: #92351A;
        display: block;
        width: 50%;
        border-radius: 4px;
        margin-bottom: 10px;
        text-align: center;
        padding: 7.5px 16px;
        background-color: #FFE7E0;
        border: 1px solid #FF7244;
        font-size: 14px;
        margin-right: auto;
        margin-left: auto;
        transition: background 0.3s;
        cursor: pointer;
    }
    .alert-res-success{
        color: #005829;
        display: block;
        width: 50%;
        border-radius: 4px;
        margin-bottom: 10px;
        text-align: center;
        padding: 7.5px 16px;
        background-color: #CDEEDB;
        border: 1px solid #00A94E;
        font-size: 14px;
        margin-right: auto;
        margin-left: auto;
        transition: background 0.3s;
        cursor: pointer;
    }
    .submit{
        color: #0083D7;
        width: 40%;
        border-radius: 4px;
        text-align: center;
        padding: 7.5px 16px;
        background-color: #e1f5fe;
        border: 1px solid #0083D7;
        font-size: 14px;
        margin-bottom: 0px !important;
        transition: background 0.3s;
        cursor: pointer;
    }
    .ajax_progress{
    height: 20px;
    right: 0;
    width: 40%;
    top: 0;
    display: inline-grid;
    position: absolute;
    }
    .upload-btn{
    position: relative;
    input[type=file]{
        position: absolute;
        top: 0px;
        left: 0px;
        opacity: 0;
        width: 100%;
        height: 100%;
    }
    }
    .upload_wrapper_pict{
    vertical-align: middle;
    }
    .upload_wrapper_pict.upload_w100,.upload_wrapper_pict.upload_w100 .file-upload_pict{
    display:block;
    }
    .imageSRC{
        max-height: 200px !important;
        max-width: 100% !important;
    }
    .contain{
        margin-top: 30px;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
    }
    .box-file{
        margin: auto;
        height: auto;
        margin-top: 25%;
    }  
    .help-block{
        width: 170px !important;
        display: block !important;
        word-break: break-word !important;
    }
</style>